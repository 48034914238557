import React, { useState } from 'react';
import styles from '../styles/InspiroBot.module.css';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import ContentCard from '../components/ContentCard';

const InspiroBot = () => {
  const [imageUrl, setImageUrl] = useState('');

  const fetchImage = async () => {
    try {
      const response = await fetch('https://inspirobot.me/api?generate=true&oy=vey');
      if (response.ok) {
        const imageUrl = await response.text();
        setImageUrl(imageUrl);
      } else {
        console.error('Response was not ok.');
      }
    } catch (error) {
      console.error('Fetch image failed', error);
    }
  };

  return (
    <div className={styles.inspirobotPage}>
      <Header title="InspiroBot" />
      <NavBar />
      <ContentCard title="Your Unique Inspirational Qoute" centerTitle={true}>
        <div className={styles.imageContainer}>
          {imageUrl && <img src={imageUrl} alt="Generated by InspiroBot.me" />}
        </div>

        <div className={styles.buttonContainer}>
          <button onClick={fetchImage} className={styles.generateButton}>Generate</button>
        </div>
        <div className={styles.buttonContainer}>
          <p>Credits: <a href="https://inspirobot.me" target="_blank" rel="noopener noreferrer">InspiroBot.me</a></p>
        </div>
      </ContentCard>
    </div>
  );
};

export default InspiroBot;
